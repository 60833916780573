import React, { Component } from 'react';

import './materials.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import Annotation from 'src/components/annotation';
import PDFButton from 'src/components/pdf-button';
import { TranslatableText } from 'src/components/language';

class PatientMaterialsPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('patient/materials');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'Downloadable Patient Materials',
          french: 'Matériel téléchargeable destiné aux patients ',
        }}
        showTab={true}
        className="patient"
      >
        <h2>
          <TranslatableText
            dictionary={{
              english: 'Downloadable Patient Materials',
              french: 'Matériel téléchargeable destiné aux patients ',
            }}
          />
        </h2>
        <div className="buttons-container">
          <ul>
            <li>
              <PDFButton
                href={{
                  english: '/pdf/Starting_Hemlibra_Brochure_EN.pdf',
                  french: '/pdf/Starting_Hemlibra_Brochure_FR.pdf',
                }}
                textDict={{
                  english: 'Starting HEMLIBRA Brochure',
                  french: 'Brochure Commencer un traitement par HEMLIBRA',
                }}
              />
            </li>
            <li>
              <PDFButton
                href={{
                  english: '/pdf/My_Hemlibra_Injection_Log_EN.pdf',
                  french: '/pdf/My_Hemlibra_Injection_Log_FR.pdf',
                }}
                textDict={{
                  english: 'My HEMLIBRA Injection Log',
                  french: 'Mon journal d’injection HEMLIBRA',
                }}
              />
            </li>
            <li>
              <PDFButton
                href={{
                  english: '/pdf/Instructions_for_Use_EN.pdf',
                  french: '/pdf/Instructions_for_Use_FR.pdf',
                }}
                textDict={{
                  english: 'Instructions for Use (IFU)',
                  french: 'Mode d’emploi',
                }}
              />
            </li>
            <li>
              <PDFButton
                href={{
                  english: '/pdf/Hemlibra_Easy_Start_Reminder_Magnet_EN.pdf',
                  french: '/pdf/Hemlibra_Easy_Start_Reminder_Magnet_FR.pdf',
                }}
                textDict={{
                  english: 'HEMLIBRA Easy Start Patient Assistance Program Reminder Magnet',
                  french: 'Aimant Programme d’assistance aux patients EasySTART pour HEMLIBRA',
                }}
              />
            </li>
          </ul>
        </div>
        <Annotation />
      </Layout>
    );
  }
}

export default PatientMaterialsPage;
